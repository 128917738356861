"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = chunkArray;
function chunkArray(array) {
  var chunk = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  var i,
    j,
    newArray = [];
  for (i = 0, j = array.length; i < j; i += chunk) {
    newArray.push(array.slice(i, i + chunk));
  }
  return newArray;
}