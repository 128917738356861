import React from 'react';
import { Link } from 'gatsby';

//import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default function TransitionLink({
    to,
    activeClassName,
    children,
    ...props
}) {
    if (!to) {
        to = '#';
    }

    if (!to.indexOf('http') || !to.indexOf('mailto:') || to.startsWith('#')) {
        return (
            <a
                href={to}
                {...props}
                {...(props.target &&
                    props.target === '_blank' && {
                        rel: 'noreferrer noopener',
                    })}>
                {children}
            </a>
        );
    }

    let cleanTo = to.endsWith('/') ? to : `${to}/`;

    if (!cleanTo.startsWith('/')) {
        cleanTo = `/${cleanTo}`;
    }

    //if cleanTo has a hash, separate parts and remove final slash
    if (cleanTo.includes('#')) {
        let parts = cleanTo.split('#');
        if (!parts[0].endsWith('/')) {
            parts[0] += '/';
        }
        cleanTo = parts.join('#').replace(/\/$/, '');
    }

    return (
        <Link
            to={cleanTo}
            activeClassName={activeClassName ? activeClassName : null}
            {...props}>
            {children}
        </Link>
    );
}
