"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getRandomInt;
function getRandomInt() {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var min = Math.ceil(10000);
  var max = Math.floor(99999);
  return "".concat(prefix).concat(Math.floor(Math.random() * (max - min + 1)) + min);
}