"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = animateScroll;
var _getWindowScroll = _interopRequireDefault(require("./getWindowScroll"));
var _animate = _interopRequireDefault(require("./animate"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function animateScroll(yScrollPoint) {
  var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var speed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.1;
  var currentScroll = (0, _getWindowScroll["default"])();
  var toY = yScrollPoint < 5 ? 0 : yScrollPoint + extra;
  (0, _animate["default"])(window.document.scrollingElement || window.document.documentElement, 'scrollTop', '', currentScroll.y || 0, toY || 0, Math.abs(toY - currentScroll.y) * speed, true);
}