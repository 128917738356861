"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getWindowScroll;
function getWindowScroll() {
  if (typeof window === 'undefined' || typeof window.document === 'undefined') {
    return {
      x: 0,
      y: 0
    };
  }
  var supportPageOffset = window.pageXOffset !== undefined;
  var isCSS1Compat = (window.document.compatMode || '') === 'CSS1Compat';
  return {
    x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? window.document.documentElement.scrollLeft : window.document.body.scrollLeft,
    y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? window.document.documentElement.scrollTop : window.document.body.scrollTop
  };
}