import React, { createContext, useContext } from "react"
import { getPageData } from "../utils/page"

const PageContext = createContext({})

export function usePageContext() {
  return useContext(PageContext)
}

export const PageProvider = ({
  children,
  pageContext,
  themeOptions,
  pathname,
}) => {
  const value = getPageData(pageContext, themeOptions, pathname)

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export default PageContext
