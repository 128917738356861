module.exports = [{
      plugin: require('../../../packages/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"sitemap":true,"primaryLanguage":"es","language":{"es":"es","en":"en","ca-ES":"es-ca","pl":"pl"},"pathsByLanguage":{},"datocms":{"previewMode":false,"modelPages":["Page","Article","News","StudyCase"],"cdnHost":"https://media.nae.global"},"slices":{"header":"/vercel/path0/apps/website/src/components/Header/index.js","footer":"/vercel/path0/apps/website/src/components/Footer/index.js"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nae,","short_name":"Nae,","start_url":"/","background_color":"#eef0f2","theme_color":"#1a6198","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b62bdd71bef2796a93a537c95c05bd8"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TW6JH63","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"routeChangeEventName":"routechange","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
