import React from 'react';
import { Button } from 'design-system';
import TransitionLink from '../TransitionLink';
import style from './style.module.scss';

function Arrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                d="M10.688 1L0.688 11 0 11.719 0.688 12.438 10.688 22.438 12.125 21 3.844 12.719 23.406 12.719 23.406 10.719 3.844 10.719 12.125 2.438z"
                transform="matrix(-1 0 0 1 23.406 0)"
            />
        </svg>
    );
}

export default function ButtonComponent({
    button = null,
    buttons = [],
    buttonClassName,
    dark,
}) {
    let theButtons = button ? [button] : buttons;

    // button.type
    // 'primary-xl'
    // TODO: 'primary-l'
    // 'secondary'

    return (
        <>
            {theButtons.map((button, i) => (
                <Button
                    key={i}
                    className={[
                        style.button,
                        style[`button--${button.type}`],
                        button.disabled ? style['button--disabled'] : '',
                        button.active ? style['button--active'] : '',
                        button.hover ? style['button--hover'] : '',
                        dark ? style['button--dark'] : '',
                        button.block ? style['button--block'] : '',
                        buttonClassName ? buttonClassName : '',
                        button.blockMobile ? style['button--block-mobile'] : '',
                    ].join(' ')}
                    block={button.block ? true : false}
                    type={
                        button.type === 'primary-xl'
                            ? 'primary'
                            : button.type === 'primary-l'
                                ? 'outline-primary'
                                : 'link'
                    }
                    isGatsby={button.link && button.link.slug ? true : false}
                    Tag={
                        button.Tag
                            ? button.Tag
                            : button.link && button.link.slug
                                ? TransitionLink
                                : 'a'
                    }
                    href={
                        button.link && button.link.slug
                            ? button.link.slug
                            : button.link?.file?.url
                                ? button.link.file.url
                                : button.externalLink
                                    ? button.externalLink
                                    : '#'
                    }
                    target={button.target ? button.target : '_self'}
                    isSubmit={button.isSubmit ? true : false}
                    onClick={button.onClick ? button.onClick : null}
                    disabled={button.disabled ? true : false}
                    extraAttributes={
                        button.extraAttributes ? button.extraAttributes : {}
                    }
                    size={button.size ? button.size : null}>
                    {button.type === 'secondary' ? (
                        <>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: button.label,
                                }}
                            />
                            <Arrow />
                        </>
                    ) : (
                        button.label
                    )}
                </Button>
            ))}
        </>
    );
}
