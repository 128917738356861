import React from 'react';
import {
    LOCAL_STORAGE_COLOR,
    changeDocumentColors,
} from './src/components/ThemePicker';
import RootWrapper from './src/components/RootWrapper';

function getNewRouteLang(pathname) {
    if (!pathname) {
        return null;
    }

    let parts = pathname.split('/').filter((i) => !!i);
    if (!parts.length) {
        return null;
    }

    if (['es', 'es-ca', 'en'].indexOf(parts[0]) !== -1) {
        return parts[0];
    }

    return null;
}

const DEFAULT_URL = `${process.env.GATSBY_URL || ''}`;

export const onClientEntry = () => {
    // Prevent client enter other domains
    if (
        process.env.GATSBY_VERCEL_ENV === 'production' &&
        DEFAULT_URL &&
        !window.location.href.includes(DEFAULT_URL)
    ) {
        window.location.href = DEFAULT_URL;
    }

    let defaultColor = window.localStorage.getItem(LOCAL_STORAGE_COLOR);
    if (defaultColor) {
        defaultColor = JSON.parse(defaultColor);
        changeDocumentColors(defaultColor);
    }
};

export const onRouteUpdate = ({ location }) => {
    const routeLang = getNewRouteLang(location.pathname);
    if (routeLang) {
        window.document.cookie = `pr_lng=${routeLang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }
};

export const wrapRootElement = ({ element }) => {
    return <RootWrapper>{element}</RootWrapper>;
};
