import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default function RootWrapper({ children }) {
    const data = useStaticQuery(graphql`
        query ColoredItems {
            allDatoCmsPage(
                filter: {
                    colorScheme: { glob: "*", ne: "Default" }
                    title: { ne: null }
                }
            ) {
                edges {
                    node {
                        slug
                    }
                }
            }
        }
    `);

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            !data?.allDatoCmsPage?.edges?.length
        ) {
            return;
        }

        const slugToRedirect = data.allDatoCmsPage.edges.find(({ node }) => {
            return node?.slug && window.location.href.indexOf(node.slug) !== -1;
        })?.node?.slug;

        if (slugToRedirect) {
            let parts = slugToRedirect.split('/').filter((i) => !!i);
            parts.pop();
            window.location.href = `${window.location.origin}/${parts.join(
                '/',
            )}/`;
        }
    }, [data]);

    return children;
}
