function getDefaultOptions(themeOptions) {
  const pathsByLanguage = themeOptions.pathsByLanguage || {}
  const language = themeOptions.language || {
    es: "",
    en: "en",
  }
  const primaryLanguage = themeOptions.primaryLanguage || "es"
  const basePath = themeOptions.basePath || `/`
  const templatesPath = themeOptions.templatesPath || `src/templates`
  const contentPath = themeOptions.contentPath || `content/pages`
  const assetPath = themeOptions.assetPath || `static/assets`
  const hosting = themeOptions.hosting ?? null
  const sitemap = themeOptions.sitemap ?? null
  const createLocalizedNotFound = themeOptions.createLocalizedNotFound ?? true
  const templateExtension = themeOptions.templateExtension ?? "js"

  return {
    language,
    pathsByLanguage,
    primaryLanguage,
    basePath,
    contentPath,
    assetPath,
    templatesPath,
    hosting,
    sitemap,
    createLocalizedNotFound,
    templateExtension,
  }
}

function getPrimaryLanguageByPathname(themeOptions, pathname) {
  const options = getDefaultOptions(themeOptions)

  if (pathname) {
    const pathParts = pathname.split("/").filter((part) => !!part)

    let primaryLanguage = options.primaryLanguage
    if (pathParts?.length) {
      Object.keys(options.language).forEach((lang) => {
        if (options.language[lang] === pathParts[0]) {
          primaryLanguage = lang
        }
      })
    }

    return primaryLanguage
  }

  return options.primaryLanguage
}

module.exports = {
  getDefaultOptions,
  getPrimaryLanguageByPathname,
}
