import React, { useMemo } from "react"
import { graphql, useStaticQuery, Slice } from "gatsby"
import { usePageContext } from "../../context/PageContext"

export function Head({ pageContext, children, customSeo, structuredData }) {
  const { seo: contextSeo, pageUrl, pagination, language } = pageContext ?? {}

  const seo = {
    ...(contextSeo ? contextSeo : {}),
    ...(customSeo ? customSeo : {}),
  }

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          suffix
          siteUrl
        }
      }
      defaultSharingImage: file(
        sourceInstanceName: { eq: "img" }
        name: { eq: "default-sharing-image" }
      ) {
        publicURL
        childImageSharp {
          resize(width: 1200, height: 630, cropFocus: CENTER) {
            src
          }
        }
      }
    }
  `)

  const defaultTitle = data.site.siteMetadata.title
  const defaultDescription = data.site.siteMetadata.description
  const url = `${data.site.siteMetadata.siteUrl}${
    !data.site.siteMetadata.siteUrl.endsWith("/") ? "/" : ""
  }`
  const suffix = data.site.siteMetadata.suffix

  const [title, description, sharingImageSrc] = useMemo(() => {
    let sharingImageSrc = null

    if (seo?.image) {
      if (seo.image.childImageSharp?.resize) {
        sharingImageSrc = seo.image.childImageSharp.resize.src
      } else if (seo.image.gatsbyImageData?.images?.fallback?.src) {
        sharingImageSrc = seo.image.gatsbyImageData.images.fallback.src
      } else if (seo.image.publicURL) {
        sharingImageSrc = seo.image.publicURL
      }
    }

    // TODO CDNHOST: Move this default to build time?
    if (!sharingImageSrc && data?.defaultSharingImage) {
      if (data.defaultSharingImage.childImageSharp?.resize) {
        sharingImageSrc = data.defaultSharingImage.childImageSharp.resize.src
      } else if (data.defaultSharingImage.publicURL) {
        sharingImageSrc = data.defaultSharingImage.publicURL
      }
    }

    if (sharingImageSrc?.startsWith("/")) {
      sharingImageSrc = `${url}${sharingImageSrc.substr(1)}`
    }

    return [
      seo && seo.title
        ? `${seo.title}${
            !seo.removeSuffix ? ` ${seo.titleSuffix || suffix}` : ""
          }`
        : defaultTitle,
      seo && seo.description ? seo.description.trim() : defaultDescription,
      sharingImageSrc,
    ]
  }, [seo, suffix, defaultTitle, defaultDescription, data, url])

  const structuredDataArray = useMemo(() => {
    let array = []

    if (structuredData) {
      array = Array.isArray(structuredData) ? structuredData : [structuredData]
    }

    return array.map((sd) => (typeof sd === "string" ? sd : JSON.stringify(sd)))
  }, [structuredData])

  return (
    <>
      {!!language && <html lang={language} />}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      {!!seo.noIndex && <meta name="robots" content="noindex" />}
      {!!pageUrl && (
        <>
          <link rel="canonical" href={pageUrl} />
          <meta property="og:url" content={pageUrl} />
          <meta property="twitter:url" content={pageUrl} />
        </>
      )}
      {structuredDataArray.map((structuredData, index) => (
        <script key={`ld-json-${index}`} type="application/ld+json">
          {structuredData}
        </script>
      ))}
      {!!description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="twitter:description" content={description} />
        </>
      )}
      {!!sharingImageSrc && (
        <>
          <meta property="og:image" content={sharingImageSrc} />
          <meta property="twitter:image" content={sharingImageSrc} />
        </>
      )}
      {!!pagination && (
        <>
          {!!pagination.previousUrl ? (
            <link rel="prev" href={pagination.previousUrl} />
          ) : null}
          {!!pagination.nextUrl ? (
            <link rel="next" href={pagination.nextUrl} />
          ) : null}
        </>
      )}
      {!!seo?.alternateLanguages &&
        seo.alternateLanguages.map(({ language, slug }) => (
          <link
            key={`hreflang-${language}`}
            rel="alternate"
            hrefLang={language}
            href={`${url.slice(0, -1)}${slug}`}
          />
        ))}
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary_large_image" />
      {children}
    </>
  )
}

export default function Layout({
  hideHeader,
  hideFooter,
  footerProps = {},
  headerProps = {},
  ...props
}) {
  const { alternateLanguages } = usePageContext()

  return (
    <>
      {!hideHeader && (
        <Slice
          alias="header"
          allowEmpty
          layoutProps={headerProps}
          alternateLanguages={alternateLanguages}
        />
      )}
      <main {...props} />
      {!hideFooter && (
        <Slice
          alias="footer"
          allowEmpty
          layoutProps={footerProps}
          alternateLanguages={alternateLanguages}
        />
      )}
    </>
  )
}
