exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-study-case-js": () => import("./../../../src/templates/study-case.js" /* webpackChunkName: "component---src-templates-study-case-js" */),
  "slice---src-components-footer-index-js": () => import("./../../../src/components/Footer/index.js" /* webpackChunkName: "slice---src-components-footer-index-js" */),
  "slice---src-components-header-index-js": () => import("./../../../src/components/Header/index.js" /* webpackChunkName: "slice---src-components-header-index-js" */)
}

